
@include media-query(small-up) {
    .login {
        padding-top: 3.75rem;
        padding-bottom: 12rem;
    }

    .login--checkout {
        padding-top: 7.5rem;
        padding-bottom: 19.75rem;
    }
}

@include media-query(small) {
    .login {
        padding-top: 2.75rem;
        padding-bottom: 5rem;
    }

    .login__section {
        &:not(:last-child) {
            margin-bottom: 4rem;
        }
    }
}