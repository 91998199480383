@import 'propertyMixins';

/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/

// Font families
$font-family-sans   : Roboto, Helvetica, Arial, sans-serif;
$font-family-sans-secondary : "IBM Plex Sans", Helvetica, Arial, sans-serif;
$font-family-serif  : "Playfair Display", Times, serif;
$font-family-cursive: cursive;

// Larger than headings
// $font-size-yotta  : 8.875rem;  // 142px
// $font-size-zetta  : 7.375rem;  // 118px
// $font-size-exa    : 6.25rem;   // 100px

// Headings
$font-size-h1      : 3.75rem;   // 60px
$font-size-h2      : 3.25rem; // 52px
$font-size-h3      : 2.75rem;   // 44px
$font-size-h4      : 2.25rem; // 36px
$font-size-h5      : 2rem;   // 32px
$font-size-h6      : 1.5rem; // 24px

// Body font sizes
$font-size-mega    : 1.375rem; //22px
$font-size-kilo    : 1.25rem; //20px
$font-size-hecto   : 1.125rem; //18px
$font-size-deka    : 1rem; //16px

$font-size-base    : .875rem; // 14px Base font size

$font-size-deci    : .8125rem; // 13px
$font-size-centi   : .75rem; // 12px
$font-size-milli   : .6875rem; // 11px
$font-size-micro   : .625rem; // 10px


// Font Weights
$font-weight-extralight : 200;
$font-weight-light    : 300;
$font-weight-normal   : 400;
$font-weight-semibold : 500;
$font-weight-bold     : 700;
$font-weight-extrabold: 800;

// Font Line Height

$font-line-height-giga  : 2.18;
$font-line-height-mega  : 1.9;
$font-line-height-kilo  : 1.71;
$font-line-height-hecto : 1.63;
$font-line-height-deka  : 1.5;
$font-line-height-base  : 1.35;
$font-line-height-deci  : 1.2;
$font-line-height-centi : 1.05;
$font-line-height-milli : 0.85;

/*------------------------------------*\
  #COLORS
\*------------------------------------*/

$color-white: #FFF;
$color-black: #000;

//GREYSCALE COLORS
$color-grey-1: #F6F5F5;
$color-grey-2: #E1E1E1;
$color-grey-3: #cdcdcd;
$color-grey-4: #737373;
$color-grey-5: #666666;
$color-grey-6: #485363;
$color-grey-7: #14100B;
$color-grey-8: #7d7d7d;

$color-red: #e60000;
$color-blue: #4D96E7;
$color-green: #00D3A7;
$color-yellow: #E7B04D;
$color-beige: #f5f5dc;

//SOCIAL MEDIA COLORS
$color-facebook-blue: #3b5998;
$color-google-blue: #4285F4;
$color-google-red: #DB4437;
$color-instagram-pink: #C62A81;
$color-instagram-orange: #F06430;

$color-paypal-blue: #009cde;
$color-paypal-silver: #eeeeee;

$color-primary: #14100B;
$color-secondary: #00A3B2;
$color-tertiary: $color-yellow;

$color-accent-primary: #0DFFCB;
$color-accent-secondary: #DAFF79;
$color-accent-tertiary: #28BAFF;

$colors: (
    white: $color-white,
    black: $color-black,

    //GREYSCALE COLORS
    grey-1: $color-grey-1,
    grey-2: $color-grey-2,
    grey-3: $color-grey-3,
    grey-4: $color-grey-4,
    grey-5: $color-grey-5,
    grey-6: $color-grey-6,
    grey-7: $color-grey-7,

    red: $color-red,
    blue: $color-blue,
    navy: darken($color-blue, 35%),
    green: $color-green,
    beige: $color-beige,

    //SOCIAL MEDIA COLORS
    facebook-blue: $color-facebook-blue,
    google-blue: $color-google-blue,
    google-red: $color-google-red,
    instagram-pink: $color-instagram-pink,
    instagram-orange: $color-instagram-orange,

    paypal-blue: $color-paypal-blue,
    paypal-silver: $color-paypal-silver,

    primary: $color-primary,
    secondary: $color-secondary,
    tertiary: $color-tertiary,

    accent-primary: $color-accent-primary,
    accent-secondary: $color-accent-secondary,
    accent-tertiary: $color-accent-tertiary
);

//UI COLORS
$color-error: $color-red;
$color-success: darken($color-green, 15%);

$color-body-copy: $color-grey-7;
$color-body-background: $color-white;

/*------------------------------------*\
  #ELEMENT-DEFAULTS
\*------------------------------------*/
$input-border-radius: 0;

/*------------------------------------*\
  #HORIZONTAL-SPACING
\*------------------------------------*/

$gutter-inner: .9375rem; //base
$gutter-inner-deci: .46875rem;

$gutter-outer-kilo: 4.6rem;
$gutter-outer: 3.4375rem; //base
$gutter-outer-deci: 1.5rem;
$gutter-outer-centi: 1.125rem;
$gutter-outer-micro: .875rem;

/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: (
    unit           : 0.0625rem, // 1
    xxsmall        : 20rem, // 320
    xsmall         : 30rem, // 480
    small          : 47.9375rem, // 767
    medium         : 64rem, // 1,024
    xmedium        : 76rem, // 1,216
    large          : 90rem // 1,440
);


/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/

// Transition durations
// **** If this is updated, make sure FEDConstants.js is also updated. ***
$transition-speed-slower    :   1s;
$transition-speed-slow      :   600ms;
$transition-speed-mid-slow  :   500ms;
$transition-speed-normal    :   426ms;
$transition-speed-medium    :   320ms;
$transition-speed-fast      :   213ms;
$transition-speed-faster    :   107ms;
$transition-speed-fastest   :   54ms;

// Transition easing
$transition-ease-default    :   ease-in-out;
$transition-ease-in         :   ease-in;
$transition-ease-out        :   ease-out;
$transition-ease-bezier     :   cubic-bezier(.4,.9,.4,1);
$transition-ease-bounce     :   cubic-bezier(.4,.9,.3,1.25);
$transition-ease-big-bounce :   cubic-bezier(.4,.9,.3,2.25);

