/**
 * TABLE OF CONTENTS
 *
 * SETTINGS
 * Global..................Globally-available variables and config.
 *
 * TOOLS
 * Mixins..................Useful mixins.
 *
 * COMPONENTS
 * 
 * 
 * MODULES
 *
 */
/*------------------------------------*\
  #SETTINGS
\*------------------------------------*/
/**
 * Returns the value of the `$key` value of a provided `$map`.
 */
/**
 * Uses `getProperty()` to return a value from the `$colors` map.
 */
/**
 * Uses `getProperty()` to return a value from the `$breakpoints` map.
 */
/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
  #COLORS
\*------------------------------------*/
/*------------------------------------*\
  #ELEMENT-DEFAULTS
\*------------------------------------*/
/*------------------------------------*\
  #HORIZONTAL-SPACING
\*------------------------------------*/
/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/
/*------------------------------------*\
  #TOOLS
\*------------------------------------*/
/**
 * Responsive Mixin
 * This mixin is designed for a _cleaner_ first approach
 * This means that css isn't overridden but rather replaced for
 * different viewport widths; making it easier to inspect/debug css
 *
 * Usage:
 * @include media-query(exclude-medium)    { ... }
 * @include media-query(medium-up-to-site) 	   { ... }
 * ... etc
 */
/**
 * [Adds styles to allow an element's height scale proportionatelly]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 */
/**
 * [Calculates the percentage aspect ratio (what % height is compared to the width)]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
/**
 * *****REMEMBER TO USE MARGIN OR PADDING AND NOT TOP/BOTTOM******
 * [Calculates the center of an element in relation to a provided width and height.
 * Useful to place an absolute element at the center of another when
 * the positioned element cannot be the target's child ]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
/**
 * [In the  awful case you can't use Flexbox to vertically align something]
 * @param  {[type]} $transform:       false         [Use transforms to align instead of the absolute trick]
 * @param  {[type]} $pos:             absolute      [position type]
 * @param  {[type]} $posAdj:          0             [position adjustment. If $transform is true, this value only affects the 'top' property.]
 * @param  {[type]} $alignHorizontal: false         [Includes horizontal alignment]
 */
/**
 * Generate Enumerated Class
 * Iterates from 0 to the specified length and generates classes that set the specified property
 * @param  {[String]} $classname:       required      [Required: Specify the class name]
 * @param  {[String]} $property:        $classname    [Optional: Specify the enumerated property (if it's different from the name of the class)]
 * @param  {[Number]} $length:          10            [Optional: Specify the end of the loop]
 * @param  {[String]} $units:           null          [Optional: specify units to append to the enumerated property]
 */
/**
 * [Strip the pesky units from values]
 * @param  {[Number]} $value
 */
/**
 * [Fluid Type]
 */
/*------------------------------------*\
  #EXTERNALS
\*------------------------------------*/
.store-locator__detect-location {
  margin-top: .5rem; }

.store-locator__result-check:checked + .store-locator__result-content {
  background-color: #E1E1E1; }

.store-locator__result-content {
  display: block;
  padding: 1.25rem 1rem; }
  .store-locator__result-content:hover {
    cursor: pointer;
    background-color: #F6F5F5; }

.store-locator__form {
  margin-bottom: 3rem; }

.store-locator__results {
  overflow: auto; }

.store-locator__result-item {
  position: relative;
  border-bottom: solid 0.0625rem #E1E1E1; }

.store-locator__no-results {
  margin: auto; }

.store-locator__actions {
  padding-top: 1rem;
  padding-bottom: 1rem; }

@media (min-width: 48rem) {
  .store-locator__results {
    max-height: 30rem; } }

@media (max-width: 47.9375rem) {
  .store-locator--modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: inherit; }
    .store-locator--modal .store-locator__form {
      flex-shrink: 0; }
    .store-locator--modal .store-locator__results {
      flex-shrink: 1;
      max-height: none; }
    .store-locator--modal .store-locator__actions {
      margin-top: auto; }
  .store-locator__results {
    max-height: 22rem; } }

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/
@media (min-width: 48rem) {
  .login {
    padding-top: 3.75rem;
    padding-bottom: 12rem; }
  .login--checkout {
    padding-top: 7.5rem;
    padding-bottom: 19.75rem; } }

@media (max-width: 47.9375rem) {
  .login {
    padding-top: 2.75rem;
    padding-bottom: 5rem; }
  .login__section:not(:last-child) {
    margin-bottom: 4rem; } }
