/**
 * Responsive Mixin
 * This mixin is designed for a _cleaner_ first approach
 * This means that css isn't overridden but rather replaced for
 * different viewport widths; making it easier to inspect/debug css
 *
 * Usage:
 * @include media-query(exclude-medium)    { ... }
 * @include media-query(medium-up-to-site) 	   { ... }
 * ... etc
 */
@function getQueryName($query, $search, $type: start) {
  @if $type == end {
    $brkptEnd: str-slice($query, str-index($query, $search) + str-length($search));

    @return map-get($breakpoints, $brkptEnd);
  }

  @else {
    $brkptStart: str-slice($query, 0, str-index($query, $search) - 1);

    @return map-get($breakpoints, $brkptStart) + map-get($breakpoints, unit);
  }
}

@mixin media-query($query) {
  $media-append: '(max-width: #{map-get($breakpoints, $query)})';

  @if str-index($query, '-up-to-') {
    $lowerBrkPt: getQueryName($query, '-up-to-');
    $higherBrkPt: getQueryName($query, '-up-to-', end);

    $media-append: '(min-width: #{$lowerBrkPt}) and (max-width: #{$higherBrkPt})';
  }

  @else if str-index($query, '-up') {
    $lowerBrkPt: getQueryName($query, '-up');

    $media-append: '(min-width: #{$lowerBrkPt})';
  }

  @media #{$media-append} {
    @content;
  }
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/**
 * [Adds styles to allow an element's height scale proportionatelly]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 */
@mixin aspect-ratio($w, $h, $usePseudo: false, $adjust: 0px) {

	$calcH: 	aspect-ratio-height($w, $h);
	$finalH: 	if( $adjust != 0px, calc( #{$calcH} + #{$adjust} ), $calcH );

	@if ($usePseudo) {
		position: relative;

		&:before {
			display: block;
			content: '';
			padding-bottom: $finalH;
			width: 100%;
		}
	}

	@else {
		height: 0;
		padding-bottom: $finalH;
	}
}

/**
 * [Calculates the percentage aspect ratio (what % height is compared to the width)]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
@function aspect-ratio-height($width, $height){
  @return percentage($height / $width);
}

/**
 * *****REMEMBER TO USE MARGIN OR PADDING AND NOT TOP/BOTTOM******
 * [Calculates the center of an element in relation to a provided width and height.
 * Useful to place an absolute element at the center of another when
 * the positioned element cannot be the target's child ]
 * @param  {[Number]} $width
 * @param  {[Number]} $height
 * @return {[Number(%)]}
 */
@function center-from-width($width, $height){
  @return aspect-ratio-height($width, $height) / 2;
}

/**
 * [In the  awful case you can't use Flexbox to vertically align something]
 * @param  {[type]} $transform:       false         [Use transforms to align instead of the absolute trick]
 * @param  {[type]} $pos:             absolute      [position type]
 * @param  {[type]} $posAdj:          0             [position adjustment. If $transform is true, this value only affects the 'top' property.]
 * @param  {[type]} $alignHorizontal: false         [Includes horizontal alignment]
 */
@mixin vertical-align($alignHorizontal: false, $transform: false, $pos: absolute, $posAdj: 0px){

	@if ($transform) {
		position: $pos;
		top: calc(50% + #{$posAdj});

		@if ($alignHorizontal) {
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
		}

		@else {
			transform: translate3d(0, -50%, 0);
		}
	}

	@else {
		position: absolute;
		top: $posAdj;
		bottom: $posAdj;

		@if ($alignHorizontal) {
			left: $posAdj;
			right: $posAdj;
			margin: auto;
		}

		@else {
			margin-top: auto;
			margin-bottom: auto;
		}
	}
}

@mixin set-visible(){
  opacity: 1;
  visibility: inherit;
}

@mixin set-invisible(){
  opacity: 0;
  visibility: hidden;
}


/**
 * Generate Enumerated Class
 * Iterates from 0 to the specified length and generates classes that set the specified property
 * @param  {[String]} $classname:       required      [Required: Specify the class name]
 * @param  {[String]} $property:        $classname    [Optional: Specify the enumerated property (if it's different from the name of the class)]
 * @param  {[Number]} $length:          10            [Optional: Specify the end of the loop]
 * @param  {[String]} $units:           null          [Optional: specify units to append to the enumerated property]
 */
@mixin generate-enumerated-class($classname, $property: $classname, $length: 10, $units: null) {
  @for $i from 0 through $length {
    .#{$classname}-#{$i}#{$units} {
      #{$property}: #{$i}#{$units};
    }
  }
}

// as above but includes an optional prefix (default: bequeath-children) and a selector to hit only immediate children
@mixin generate-enumerated-parent($classname, $property: $classname, $length: 10, $units: null, $prefix: bequeath-children) {
  @for $i from 0 through $length {
    .#{$prefix}-#{$classname}-#{$i}#{$units} > * {
      #{$property}: #{$i}#{$units};
    }
  }
}

/// A small function allowing skipping base64 encoding
/// and simply pasting the SVG markup right in the CSS.
/// @author Jakob Eriksen
/// @link http://codepen.io/jakob-e/pen/doMoML
/// @param {String} $svg - SVG image to encode
/// @return {String} - Encoded SVG data uri
@function svg-uri($svg) {
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');
        $chunk: str-replace($chunk, '&', '%26');
        $chunk: str-replace($chunk, '#', '%23');
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return "data:image/svg+xml;charset=utf8,#{$encoded}";
}

//SVG Mixins.
//These are used when you need to serve the same SVG as background/content from
//CSS while keeping the ability to render different colors.
@function get-icon($iconName, $color: $color-body-copy) {
  $iconCode: "";
  $transformedColor: str-replace(#{$color}, '#', '%23');

  @if $iconName == angle-down {
    $iconCode: "viewBox='0 0 24 24'><path fill='#{$transformedColor}' d='M23.3 5.6c-.9-.9-2.3-.9-3.2 0l-8.2 8.1-8-8.1c-.9-.9-2.3-.9-3.2 0-.5.4-.7 1-.7 1.6s.2 1.2.7 1.6l9.7 9.7c.4.4 1 .7 1.6.7.6 0 1.2-.2 1.6-.7l9.8-9.7c.4-.4.7-1 .7-1.6s-.3-1.2-.8-1.6z'/>";
  }

  @else if $iconName == close {
    $iconCode: " viewBox='0 0 24 24'><path fill='#{$transformedColor}' d='M13.7 12l10-10c.5-.5.5-1.2 0-1.7s-1.2-.5-1.7 0l-10 10L2 .4C1.5-.1.8-.1.3.4s-.5 1.2 0 1.7l10 10-10 10c-.5.5-.5 1.2 0 1.7.3.1.6.2.9.2s.6-.1.8-.4l10-10 10 10c.2.2.5.4.8.4.3 0 .6-.1.8-.4.5-.5.5-1.2 0-1.7L13.7 12z'/>";
  }
  @else if $iconName == plus {
    $iconCode: " viewBox='0 0 40 40'><path fill='#{$transformedColor}' d='M40 17.857H22.143V0h-4.286v17.857H0v4.286h17.857V40h4.286V22.143H40z' />";
  }

  @else if $iconName == minus {
    $iconCode: " viewBox='0 0 40 5'><path fill='#{$transformedColor}' d='M0 0h40v4.5H0z' />";
  }

  @else if $iconName == checkmark {
    $iconCode: " viewBox='0 0 24 24'><path fill='#{$transformedColor}' d='M23.5 3.8c-.3-.3-.8-.5-1.2-.5-.5 0-.9.2-1.2.5L8.3 16.5 3 11.2c-.4-.4-1.1-.6-1.7-.4-.6.2-1.1.7-1.2 1.3-.2.6 0 1.2.5 1.7l6.6 6.6c.3.3.8.5 1.2.5.5 0 .9-.2 1.2-.5L23.5 6.3c.3-.3.5-.8.5-1.3 0-.4-.2-.9-.5-1.2z' />";
  }

  @return svg-uri("<svg xmlns='http://www.w3.org/2000/svg' #{$iconCode}</svg>");
}

 /**
 * [Strip the pesky units from values]
 * @param  {[Number]} $value
 */
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

 /**
 * [Fluid Type]
 */
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin tooltip-arrows($position: top) {
  $reversePosition: if($position == bottom, top, bottom);

  &.set--right {
    transform-origin: $reversePosition right;
    border-#{$reversePosition}-right-radius: 0;
  }

  &.set--left {
    transform-origin: $reversePosition left;
    border-#{$reversePosition}-left-radius: 0;
  }

  &:before {
    #{$position}: calc(100% + .0625rem);
    border-#{$position}: .7125rem solid $color-grey-7;
  }
}
