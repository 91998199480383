.store-locator__detect-location {
    margin-top: .5rem;
}

.store-locator__result-check {
    &:checked + .store-locator__result-content {
        background-color: $color-grey-2;
    }
}

.store-locator__result-content {
    display: block;
    padding: 1.25rem 1rem;

    &:hover {
        cursor: pointer;
        background-color: $color-grey-1;
    }
}

.store-locator__form {
    margin-bottom: 3rem;
}

.store-locator__results {
    overflow: auto;
}

    .store-locator__result-item {
        position: relative;
        border-bottom: solid .0625rem $color-grey-2;
    }

.store-locator__no-results {
    margin: auto;
}

.store-locator__actions {
    padding-top: 1rem;
    padding-bottom: 1rem;
}


@include media-query(small-up) {
    .store-locator__results {
        max-height: 30rem;
    }
}

@include media-query(small) {
    .store-locator--modal {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: inherit;

        .store-locator__form {
            flex-shrink: 0;
        }

        .store-locator__results {
            flex-shrink: 1;
            max-height: none;
        }

        .store-locator__actions {
            margin-top: auto;
        }
    }

    .store-locator__results {
        max-height: 22rem;
    }
}